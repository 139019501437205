@use "sass:math"; @import "abstract";
.consultingConditions {
  display: grid;
  gap: 20px;
  grid-area: consulting_conditions;
  height: fit-content;
  padding: 24px 56px 0;

  &__item {
    align-items: center;
  }
}

@include mq($until: desktop) {

  .consultingConditions {
    align-self: start;
    padding: 32px;
  }
}

@include mq($until: tablet) {

  .consultingConditions {
    gap: 8px;
    padding: 8px 16px 0;
  }
}
