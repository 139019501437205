@use "sass:math"; @import "abstract";
.financingProductSuccess {

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
  }

  &__header {
    text-align: center;
  }

  &__icon {
    margin: 0 auto;
  }

  &__title {
    margin-top: 8px;
  }

  &__subtitlte {
    color: $color-text-secondary;
    margin-top: 8px;
  }

  &__main {
    width: 100%;
  }

  &__actions {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: center;
  }
}

@include mq($until: tablet) {

  .financingProductSuccess {

    &__footer {
      width: 100%;
    }

    &__actions {
      flex-direction: column;
      gap: 16px;
    }
  }
}
