@use "sass:math"; @import "abstract";
.financingConsulting {
  display: flex;
  min-height: 528px;

  &__info {
    position: relative;
    width: 665px;

    &--followingStep {

      &::after {
        border-right: 1px solid $color-control-border-primary;
        bottom: 40px;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 40px;
      }
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex: 1;
    justify-items: center;
  }

  &__formFooter {
    color: $color-text-secondary;
  }

  &__header {
    padding: 40px 56px 0;
  }

  &__success {
    padding: 40px 56px;
  }

  &__error {
    margin: 0 auto;
    padding: 40px 56px;
  }

  &__successWrapper {
    gap: 24px;
  }

  &__successTitle {
    margin: 0;
  }

  &__successSubtitle {
    color: $color-text-secondary;
  }

  &__successItem {
    padding: 14px 16px;
    row-gap: 8px;
  }

  &__successNavIcon {
    height: 80px;
    min-width: 80px;
  }

  &__communicationMethod {
    flex: 1;
    gap: 40px;
    justify-content: center;
    padding: 24px 56px;
  }
}

@include mq($until: desktop) {

  .financingConsulting {

    &__info {
      flex: 1 0 50%;
      width: unset;

      &--followingStep {

        &::after {
          display: none;
        }
      }
    }

    &__form {
      flex: 1 0 50%;
      min-width: 0;
    }

    &__header {
      padding: 40px 32px 0;
    }

    &__communicationMethod {
      gap: 24px;
      margin: 0 auto;
      max-width: 333px;
      min-height: unset;
      padding: 24px 32px;
    }

    &__communicationMethodContainer {
      flex-direction: column;
    }

    &__success {
      margin: 0 auto;
      max-width: 360px;
      min-width: 0;
      padding: 38px 16px;

      .navSuccessNew {
        display: flex;
        flex-direction: column;
      }

      .navSuccessNewItem {
        padding: 8px 16px;
      }
    }

    &__successNav {
      grid-template-columns: 1fr;
    }

    &__successNavIcon {
      height: 64px;
      min-width: 64px;
      width: 64px;
    }

    &__successItem {
      flex-direction: row;
      gap: 16px;
    }
  }
}

@include mq($until: tablet) {

  .financingConsulting {
    display: block;
    margin: 0 -16px;
    min-height: unset;

    &__info {

      &--followingStep {
        display: none;
      }
    }

    &__header {
      padding: 0 16px;
    }

    &__success {
      max-width: unset;
      padding: 24px 16px;
      width: 100%;

      .navSuccessNewItem__title {
        flex: 1;
        max-width: unset;
      }
    }

    &__communicationMethod {
      gap: 24px;
      margin: 0;
      max-width: unset;
      padding: 24px 16px;
    }

    &__communicationMethodTitle {
      text-align: left;
    }

    &__communicationMethodButton {
      width: 100%;
    }

    .consultingFormHeader__title {
      display: none;
    }
  }
}
