@use "sass:math"; @import "abstract";
.financingConsultingFormStep {

  &__footer {
    color: $color-text-secondary;
  }

  &__registrationForm {
    min-width: 0;
  }
}
