@use "sass:math"; @import "abstract";
.consultingHeader {
  grid-area: consulting_header;
  padding: 72px 56px 8px;

  &__badge {
    margin-bottom: 24px;
  }

  &__title {
    white-space: pre-wrap;
  }
}

@include mq($until: desktop) {

  .consultingHeader {
    padding: 48px 0 0 32px;
  }
}

@include mq($until: tablet) {

  .consultingHeader {
    padding: 24px 16px 0;

    &__badge {
      margin-bottom: 16px;
    }

    &__title {
      padding-bottom: 8px;
    }
  }
}
